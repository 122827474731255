import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Link as LinkText,
} from "@chakra-ui/react";
import { useModal } from "@ebay/nice-modal-react";
import { FadeIn } from "components/fade-in-presence";
import { useParams } from "hooks/use-params";
import { MultiFactorEnrolment } from "screens/profile/dialogs/multi-factor-enrolment";
import { Link } from "wouter";

export function FormError({ error }: { error: string | undefined }) {
  const { app_id } = useParams();
  const mfaEnrolmentModal = useModal(MultiFactorEnrolment);

  return (
    <FadeIn isVisible={!!error} childKey={"error"}>
      {error ===
      "Multi-factor authentication is mandatory for at least one account you have access to" ? (
        <Box pb={"4"}>
          <Alert
            status={"error"}
            key={error}
            alignItems={"flex-start"}
            borderRadius={"md"}
            p={4}
          >
            <AlertIcon />
            <AlertDescription lineHeight={1.4}>
              Multi-factor authentication is mandatory for at least one account
              you have access to
              <Button
                size={"sm"}
                color={"black"}
                colorScheme={"gray"}
                _hover={{ color: "blackAlpha.600" }}
                mt={"4"}
                onClick={() =>
                  mfaEnrolmentModal.show({
                    onEnrolmentComplete: () => {
                      // Set href instead of navigate() since we want to deliberately
                      // refresh the page/clear all state
                      window.location.href = `/select-account?app_id=${app_id}`;
                    },
                  })
                }
              >
                Set up multi-factor
              </Button>
            </AlertDescription>
          </Alert>
        </Box>
      ) : (
        <Box pb={"4"}>
          <Alert
            status={"error"}
            key={error}
            alignItems={"flex-start"}
            borderRadius={"md"}
            p={4}
          >
            <AlertIcon />
            <AlertDescription lineHeight={1.4}>{error}</AlertDescription>
          </Alert>
        </Box>
      )}
      {app_id === "rex_pm" &&
      error === "These credentials do not match our records." ? (
        <Box pb={"4"}>
          <Alert
            status={"warning"}
            key={error}
            alignItems={"flex-start"}
            borderRadius={"md"}
          >
            <AlertIcon alignSelf={"flex-start"} />
            <AlertDescription lineHeight={1.4}>
              Rex PM and Rex CRM login details are now shared.
              <br />
              <br />
              If you had different passwords for Rex PM and Rex CRM, you should
              login using your Rex CRM password. Alternatively, you can{" "}
              <LinkText as={Link} to={"/forgot-password"} color={"blue.500"}>
                reset your password here.
              </LinkText>
            </AlertDescription>
          </Alert>
        </Box>
      ) : null}
    </FadeIn>
  );
}
