import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Switch,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useModal } from "@ebay/nice-modal-react";
import helpBannerSecurityImage from "assets/help-banner-security.png";
import { ReactComponent as keysIcon } from "assets/icons/keys.svg";
import { HelpBanner } from "components/help-banner";
import { PageSpinner } from "components/page-spinner";
import { axios } from "lib/axios";
import { AuditLogBlock } from "screens/profile/components/audit-log-block";
import { EditPasswordBlock } from "screens/profile/components/edit-password-block";
import { PageHeader } from "screens/profile/components/page-header";
import { MultiFactorEnrolment } from "screens/profile/dialogs/multi-factor-enrolment";
import { useProfile } from "screens/profile/hooks/use-profile";
import { navigate } from "wouter/use-location";
import { useState } from "react";

function ForceLogoutBlock() {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const handleForceLogout = async () => {
    try {
      setIsLoading(true);
      await axios.post("logout-all");
      toast({
        title: "Success",
        description:
          "Successfully logged out of all other Rex apps and other devices",
        status: "success",
        position: "top-right",
        size: "xl",
      });
      navigate("/");
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to log out other devices",
        status: "error",
        position: "top-right",
        size: "xl",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <VStack alignItems={"flex-start"}>
          <Heading size={"md"}>Force Logout</Heading>
          <Text>
            Log out of all Rex apps and devices where you&rsquo;re currently
            signed in.
          </Text>
        </VStack>
      </CardHeader>
      <CardBody pt={0}>
        <Button
          colorScheme="red"
          onClick={handleForceLogout}
          isLoading={isLoading}
        >
          Log out all apps and devices
        </Button>
      </CardBody>
    </Card>
  );
}

export function Security() {
  const { user, deleteMultiFactorEnrolment, disableMultiFactorEnrolment } =
    useProfile();
  const multiFactorEnrolment = useModal(MultiFactorEnrolment);

  if (!user) return <PageSpinner />;

  const totpEnrolment = user.multi_factor_enrolments.find(
    (e) => e.method_type === "totp"
  );

  return (
    <>
      <PageHeader
        heading={"Security"}
        Icon={keysIcon}
        description={
          "Settings and recommendations to help you keep your account secure"
        }
      />
      <VStack spacing={6} align={"stretch"}>
        <HelpBanner
          heading={"Keeping you safe and secure"}
          description={
            <>
              <Text mb={2}>
                No matter what your Rex product or service, you are in control
                over your account access.
              </Text>
              <Button
                as={"a"}
                href={
                  "https://support.rexsoftware.com/hc/en-us/articles/360024910374-Keeping-your-data-secure"
                }
                size={"sm"}
                variant={"outline"}
              >
                Learn about our security practices
              </Button>
            </>
          }
          image={helpBannerSecurityImage}
        />
        <Card>
          <CardHeader>
            <VStack alignItems={"flex-start"}>
              <Heading size={"md"}>Multi-factor Authentication</Heading>{" "}
              <Text>
                Multi-factor authentication enhances security by requiring you
                to enter a code sent to your phone when logging in. This helps
                prevent unauthorised access to your account.
              </Text>
            </VStack>
          </CardHeader>
          <CardBody pt={0}>
            <HStack justifyContent={"space-between"}>
              <VStack
                spacing={0.5}
                align={"flex-start"}
                justifyContent={"flex-start"}
                maxW={"300px"}
              >
                <HStack
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                  flex={1}
                >
                  <Heading size={"sm"}>Authenticator App</Heading>
                </HStack>
                <Text fontSize={"md"} color={"gray.600"}>
                  One time passcode generated by Google Authenticator or similar
                </Text>
              </VStack>
              <HStack>
                {totpEnrolment?.verified_at && !totpEnrolment.is_enabled ? (
                  <Button
                    variant={"ghost"}
                    onClick={() => deleteMultiFactorEnrolment(totpEnrolment.id)}
                  >
                    Delete
                  </Button>
                ) : null}
                {!totpEnrolment ? (
                  <Button onClick={() => multiFactorEnrolment.show({})}>
                    Setup
                  </Button>
                ) : !totpEnrolment.verified_at ? (
                  <Button
                    onClick={() =>
                      multiFactorEnrolment.show({
                        initialStepKey: "scanQRCode",
                      })
                    }
                  >
                    Finish setup
                  </Button>
                ) : (
                  <Switch
                    size={"lg"}
                    colorScheme={"green"}
                    isChecked={!!totpEnrolment.is_enabled}
                    onChange={() =>
                      totpEnrolment.is_enabled
                        ? disableMultiFactorEnrolment({ id: totpEnrolment.id })
                        : multiFactorEnrolment.show({
                            initialStepKey: "verifyPassword",
                          })
                    }
                  ></Switch>
                )}
              </HStack>
            </HStack>
          </CardBody>
        </Card>
        <EditPasswordBlock />
        <AuditLogBlock />
        <ForceLogoutBlock />
      </VStack>
    </>
  );
}
