import { Box, Button, Code, Flex, Text, VStack } from "@chakra-ui/react";
import { FormField } from "components/input/form-field";
import { OneTimePasswordInput } from "components/input/one-time-password-input";
import { useMultiStepWizard } from "hooks/use-multi-step-wizard";
import { useProfile } from "screens/profile/hooks/use-profile";

export function ScanQrCode({
  goToPreviousStep,
  isLoading,
}: ReturnType<typeof useMultiStepWizard>) {
  const { multiFactorEnrolment } = useProfile();
  return (
    <VStack spacing={6} alignItems={"stretch"}>
      <Text>
        Open your authenticator app and set up a new authentication method by
        scanning the following QR code:
      </Text>
      <Box
        bg={"gray.100"}
        flex={1}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={1}
      >
        <Box
          bg={"white"}
          borderRadius={"xl"}
          shadow={"sm"}
          overflow={"hidden"}
          w={"fit-content"}
          m={4}
          borderColor={"slate.200"}
          borderWidth={1}
        >
          <img
            alt={"QR CODE"}
            src={multiFactorEnrolment?.qr_code}
            width={"200"}
          />
        </Box>
        <Text fontSize={["sm", "md"]}>
          Can't scan? Enter the key manually:{" "}
          <Code fontSize={["sm", "md"]} display={"inline-block"} mt={1}>
            {multiFactorEnrolment?.method_data.secret_key}
          </Code>
        </Text>
      </Box>
      <Text>Once done, enter the 6 digit code provided by the app</Text>
      <FormField name={"code"} Input={OneTimePasswordInput} required />
      <Flex justify={"space-between"} py={"8"}>
        <Button variant={"outline"} onClick={goToPreviousStep}>
          Previous
        </Button>
        <Button type={"submit"} isLoading={isLoading}>
          Next
        </Button>
      </Flex>
    </VStack>
  );
}
