import {
  Alert,
  AlertIcon,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  Heading,
  IconButton,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ReactComponent as plugIcon } from "assets/icons/plug.svg";
import dayjs from "dayjs";
import { axios } from "lib/axios";
import { confirmationDialog } from "patterns/confirmation-dialog";
import { HiPlus, HiTrash } from "react-icons/hi";
import { showCreateTokenDialog } from "screens/profile/components/create-token-dialog";
import { PageHeader } from "screens/profile/components/page-header";

interface IntegrationAccessToken {
  id: string;
  name: string;
  scopes: string[] | string;
  rex_application_id: string;
  expires_at: string | null;
  created_at: string;
  integrator: {
    name: string;
    code: string;
  };
}

const formatScope = (scope: string) => {
  return scope === "read" ? "Read only" : "Read & write";
};

export function Integrations() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { data: tokens, isLoading } = useQuery<{
    result: IntegrationAccessToken[];
  }>({
    queryKey: ["integration-tokens"],
    queryFn: () =>
      axios
        .get(`user/integration-access-tokens`, {
          headers: { Accept: "application/json" },
        })
        .then((res) => res.data),
  });

  const handleDelete = async (id: string, name: string) => {
    try {
      await confirmationDialog({
        title: "Delete Integration Token",
        message: `Are you sure to want to delete the token "${name}"? This action cannot be undone. Once deleted, any integrator using this token will have their application access terminated.`,
        confirmText: "Delete",
        isDestructive: true,
        onConfirm: async () => {
          await axios.delete(`user/integration-access-tokens/${id}`);
          await queryClient.invalidateQueries({
            queryKey: ["integration-tokens"],
          });
          toast({
            title: "Token deleted",
            status: "success",
            position: "top-right",
            size: "xl",
            duration: 3000,
          });
        },
      });
    } catch (error) {
      toast({
        title: "Failed to delete token",
        status: "error",
        position: "top-right",
        size: "xl",
        duration: 3000,
      });
    }
  };

  return (
    <>
      <PageHeader
        heading={"Integrations"}
        Icon={plugIcon}
        description={
          "Manage access tokens for external applications that integrate on behalf of your user"
        }
      />
      <Alert
        status={"warning"}
        mb={6}
        alignItems={"flex-start"}
        borderRadius={"md"}
      >
        <AlertIcon mt={1} />
        <Text>
          <Text as={"b"} display={"block"} mb={1}>
            Early preview only
          </Text>
          We are currently in the process of rolling out new authentication
          methods for integrators. This section can be safely ignored for now;
          we will be sharing more information soon.
        </Text>
      </Alert>
      <VStack spacing={6} align={"stretch"}>
        <Card>
          <CardHeader>
            <VStack alignItems={"flex-start"} spacing={0}>
              <Flex w={"100%"} justify={"space-between"} align={"center"}>
                <Heading size={"md"}>Integration Access Tokens</Heading>
                <Button
                  leftIcon={<HiPlus />}
                  onClick={() => showCreateTokenDialog()}
                >
                  Create Token
                </Button>
              </Flex>
              <Text w={"100%"} maxW={"450px"}>
                Integration access tokens allow external applications to access
                your account on your behalf, and cannot be modified once
                created. Be careful who you share these tokens with.
              </Text>
            </VStack>
          </CardHeader>
          <CardBody pt={0}>
            {isLoading ? (
              <Center py={8}>
                <Spinner size={"lg"} color={"gray.500"} />
              </Center>
            ) : tokens?.result.length === 0 ? (
              <Center py={16} px={4}>
                <VStack spacing={4} maxW={"lg"} textAlign={"center"}>
                  <Text color={"gray.600"}>
                    You haven&apos;t created any integration tokens yet.
                    Integration tokens allow external applications to access
                    your account in a secure way.
                  </Text>
                  <Button
                    leftIcon={<HiPlus />}
                    onClick={() => showCreateTokenDialog()}
                  >
                    Create Your First Token
                  </Button>
                </VStack>
              </Center>
            ) : (
              <TableContainer>
                <Table variant={"simple"}>
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Permissions</Th>
                      <Th>Integrator</Th>
                      <Th>Expires</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {tokens?.result.map((token) => (
                      <Tr key={token.id}>
                        <Td>{token.name}</Td>
                        <Td>
                          {Array.isArray(token.scopes)
                            ? token.scopes.map(formatScope).join(", ")
                            : formatScope(token.scopes)}
                        </Td>
                        <Td>{token.integrator.name}</Td>
                        <Td>
                          {token.expires_at
                            ? dayjs(token.expires_at).format("DD MMM YYYY")
                            : "Never"}
                        </Td>
                        <Td isNumeric>
                          <IconButton
                            aria-label={"Delete token"}
                            icon={<HiTrash />}
                            variant={"outline"}
                            size={"sm"}
                            colorScheme={"gray"}
                            onClick={() => handleDelete(token.id, token.name)}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </CardBody>
        </Card>
      </VStack>
    </>
  );
}
