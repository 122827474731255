import { useSetState } from "hooks/use-set-state";
import { ComponentType, useEffect, useMemo, useState } from "react";

export type StateMachineStepReturn<State> =
  | Partial<State>
  | Promise<Partial<State> | void>
  | void;

type StateMachineStep<
  State,
  ComponentProps = any,
  FormData extends Record<string, unknown> = any
> = {
  Component: ComponentType<ComponentProps>;
  title?: string;
  onLoad?: (args: { state: State }) => StateMachineStepReturn<State>;
  onSubmit: (args: {
    data: FormData;
    state: State;
  }) => StateMachineStepReturn<State>;
  getNextStep: (state: State) => keyof StateMachineSteps<State> | null;
};

export type Action<State> = (args: {
  data: Record<string, unknown>;
  state: State;
}) => StateMachineStepReturn<State>;

export type StateMachineSteps<State> = Record<string, StateMachineStep<State>>;

export type Wizard<State extends Record<string, unknown>> = ReturnType<
  typeof useMultiStepWizard<State, any>
>;

export function useMultiStepWizard<
  State extends Record<string, unknown>,
  Steps extends StateMachineSteps<State>
>({
  initialState,
  initialStepKey,
  onError,
  steps,
}: {
  initialState: State;
  initialStepKey?: keyof Steps;
  steps: Steps;
  onError?: (
    e: Error,
    wizard: ReturnType<typeof useMultiStepWizard<State, Steps>>
  ) => { state: State; error: string | undefined };
}) {
  initialStepKey = initialStepKey || Object.keys(steps)[0];
  const [state, setState] = useSetState<State>(initialState);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [currentStepKey, setCurrentStepKey] =
    useState<keyof Steps>(initialStepKey);

  function getKeysBefore(targetKey: keyof Steps): (keyof Steps)[] {
    const keys = Object.keys(steps);
    const targetIndex = keys.indexOf(String(targetKey));

    return keys.slice(0, targetIndex);
  }

  const [stepHistory, setStepHistory] = useState<(keyof Steps)[]>(
    getKeysBefore(currentStepKey)
  );

  const currentStep: StateMachineStep<State> & {
    key: keyof Steps;
    onSubmit: typeof onSubmit;
  } = useMemo(
    () => ({
      ...steps[currentStepKey],
      key: currentStepKey,
      onSubmit,
    }),
    [currentStepKey, onSubmit]
  );

  async function onSubmit(data: any): Promise<State> {
    let newState: State, error: string | undefined;
    const action = steps[currentStepKey].onSubmit;
    if (!action) return state;
    // eslint-disable-next-line prefer-const
    ({ state: newState, error } = await handleApiAction(action, {
      state,
      data,
    }));

    if (!newState || error) return state;

    const nextStepKey = currentStep.getNextStep(newState) as keyof Steps;
    if (!nextStepKey) return newState;
    newState = await loadStep(nextStepKey, newState);
    goToNextStep(newState);
    return newState;
  }

  async function loadStep(currentStepKey: keyof Steps, state: State) {
    const onLoadFunction = (steps[currentStepKey] as StateMachineStep<State>)
      ?.onLoad;
    if (!onLoadFunction) {
      return state;
    }

    return (
      await handleApiAction(onLoadFunction, {
        state,
        data: {},
      })
    ).state;
  }

  function goToNextStep(state: State) {
    const nextStepKey = currentStep.getNextStep(state) as keyof Steps;
    if (!nextStepKey) return;

    if (!steps[nextStepKey])
      throw Error(
        `Attempted to navigate to invalid step ${String(nextStepKey)}`
      );

    setStepHistory((s) => s.concat([currentStep.key]));
    setCurrentStepKey(nextStepKey);
  }

  function goToPreviousStep() {
    setCurrentStepKey(previousStep);
    setStepHistory(stepHistory.slice(0, -1));
  }

  const previousStep = useMemo(
    () =>
      (stepHistory[stepHistory.length - 1] ||
        Object.keys(steps).find(
          (s, index) =>
            index + 1 ===
            Object.keys(steps).findIndex((s) => s === currentStepKey)
        )) as keyof Steps,
    [stepHistory]
  );

  useEffect(() => {
    if (currentStepKey === initialStepKey) {
      loadStep(currentStepKey, state);
    }
  }, [currentStepKey, initialStepKey]);

  const handleApiAction = async (
    action: Action<State>,
    args: { data: Record<string, unknown>; state: State }
  ): Promise<{ state: State; error: string | undefined }> => {
    setIsLoading(true);
    setError(undefined);

    let newState: State = { ...args.state },
      error: string | undefined;

    try {
      const statePartial = await action(args);
      newState = { ...newState, ...statePartial };
    } catch (e) {
      if (onError) {
        ({ state: newState, error } = onError(e as Error, getReturn()));
      }
    } finally {
      setState(newState);
      setIsLoading(false);
    }

    return { state: newState, error };
  };

  const resetState = () => {
    setState(() => ({ ...initialState, email: "" }));
    setStepHistory([]);
    setCurrentStepKey(initialStepKey || Object.keys(steps)[0]);
    setError(undefined);
  };

  function getReturn() {
    return {
      state,
      setState,
      currentStep,
      goToPreviousStep,
      setCurrentStepKey,
      stepHistory,
      previousStep,
      resetState,
      error,
      isLoading,
      setError,
    };
  }

  return getReturn();
}
