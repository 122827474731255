import { CrossFadeSwitch } from "components/cross-fade-switch";
import { AppLayoutPrivate } from "patterns/app-layout-private";
import { AppLayoutPublic } from "patterns/app-layout-public";
import { Login } from "screens/login";
import { Integrations } from "screens/profile/integrations";
import { PersonalInfo } from "screens/profile/personal-info";
import { Security } from "screens/profile/security";
import { Redirect, useRouter } from "wouter";
import { usePathname } from "wouter/use-location";

export const ROUTES_PUBLIC = {
  "/forgot-password": () => <Login initialStepKey={"forgotPassword"} />,
  "/reset-password/:token*": () => <Login initialStepKey={"resetPassword"} />,
  "/multi-factor-verify": () => <Login initialStepKey={"verifyMultiFactor"} />,
  "/select-account": () => <Login initialStepKey={"selectAccount"} />,
  "/": Login,
} as const;

export const ROUTES_PRIVATE = {
  "/profile/personal-info": PersonalInfo,
  "/profile/security": Security,
  "/profile/integrations": Integrations,
  "/profile": () => <Redirect to={"/profile/personal-info"} />,
} as const;

export function Router() {
  const router = useRouter();
  const pathname = usePathname();
  const matcher = router.matcher;

  const routes = { ...ROUTES_PUBLIC, ...ROUTES_PRIVATE } as const;

  const routeKey = (Object.keys(routes) as Array<keyof typeof routes>).find(
    (k) => {
      return matcher(k, pathname)[0];
    }
  );

  const Route = routeKey ? routes[routeKey] : () => null;

  const isPrivate = routeKey ? routeKey in ROUTES_PRIVATE : false;

  const Layout = isPrivate ? AppLayoutPrivate : AppLayoutPublic;

  return (
    <Layout>
      <CrossFadeSwitch childKey={pathname}>
        <Route />
      </CrossFadeSwitch>
    </Layout>
  );
}
