import { Heading } from "@chakra-ui/react";
import { CrossFadeSwitch } from "components/cross-fade-switch";
import { MetaTitle } from "components/meta-title";
import { APPS } from "config/apps";
import { useParams } from "hooks/use-params";
import { useSearchParams } from "hooks/use-search-params";
import { FormError } from "patterns/form-error";
import { useEffectAppIdLocalStorage } from "screens/login/hooks/use-effect-app-id-local-storage";
import { useEffectEmailLocalStorage } from "screens/login/hooks/use-effect-email-local-storage";
import { useEffectError } from "screens/login/hooks/use-effect-error";
import { useEffectLogout } from "screens/login/hooks/use-effect-logout";
import {
  LoginSteps,
  useLoginWizard,
} from "screens/login/hooks/use-login-wizard";

export function Login({
  initialStepKey,
}: {
  initialStepKey?: keyof LoginSteps;
}) {
  const { login_source, app_id } = useParams();
  const app = APPS.find((a) => a.id === app_id);

  const wizard = useLoginWizard({
    initialStepKey:
      login_source && !app_id
        ? "selectApp"
        : login_source
        ? "selectAccount"
        : initialStepKey,
  });

  const {
    state: { email },
    currentStep,
    error,
    setError,
  } = wizard;

  const searchParams = useSearchParams();
  const isLogout = Object.keys(searchParams).includes("logout");

  useEffectEmailLocalStorage(email);

  useEffectAppIdLocalStorage(app_id);

  useEffectLogout(isLogout);

  useEffectError(error, setError);

  let title = "Log in";
  if (app) title = `Log in to ${app.name}`;
  if (currentStep.title) title = currentStep.title;

  return (
    <>
      <MetaTitle>Log in</MetaTitle>
      <Heading mb={"8"} colorScheme={"brand"} size={["lg", "lg", "2xl"]}>
        {title}
      </Heading>
      <CrossFadeSwitch childKey={currentStep.key}>
        <FormError error={error} />
        <currentStep.Component {...wizard} />
      </CrossFadeSwitch>
      {isLogout ? (
        <iframe
          src={app?.logoutUrl}
          style={{ width: 1, height: 1, opacity: 0 }}
        />
      ) : null}
    </>
  );
}
