import { show as showModal, useModal } from "@ebay/nice-modal-react";
import { AxiosError } from "axios";
import { LoginError } from "errors/login-error";
import { useParams } from "hooks/use-params";
import { axios } from "lib/axios";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { apiActions } from "screens/login/hooks/use-login-wizard";
import { EmbeddedUpdated } from "screens/profile/dialogs/embedded-updated";
import {
  MultiFactorVerify,
  requestMultiFactorCode,
} from "screens/profile/dialogs/multi-factor-verify";
import useLocation from "wouter/use-location";

const ProfileContext = createContext<
  ReturnType<typeof useProfileInternal> | undefined
>(undefined);

export type User = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  email_verified_at?: string;
  password: string;
  remember_token?: string;
  created_at: string;
  updated_at: string;
  multi_factor_enrolments: MultiFactorEnrolment[];
};

export type MultiFactorEnrolment = {
  id: string;
  user_id: string;
  method_type: "totp";
  method_data: {
    secret_key: string;
  };
  is_enabled: boolean;
  verified_at: string | null;
  created_at: string;
  updated_at: string;
  qr_code: string;
};

let getUserPromise: Promise<User | null> | null = null;

function useProfileInternal() {
  const { error: errorParam, embedded } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(errorParam);
  const [user, setUser] = useState<User | undefined>();
  const [, setLocation] = useLocation();
  const embeddedUpdatedModal = useModal(EmbeddedUpdated);

  function handleError(
    e: Error,
    fallbackMessage = "An unexpected error occurred"
  ) {
    setIsLoading(false);
    if (e instanceof LoginError) {
      setError(e.message);
    } else if (
      e instanceof AxiosError &&
      e?.response?.data?.exception === "ValidationException"
    ) {
      setError(e?.response?.data?.message);
    } else if (
      e instanceof AxiosError &&
      e?.response?.data?.exception === "MultiFactorException"
    ) {
      showModal(MultiFactorVerify, {
        onSubmit: async ({ code }) => {
          await apiActions.verifyMultiFactor({
            data: { code, enrolment_id: e?.response?.data.enrolment_id },
          });
          window.location.reload();
        },
        isCloseable: false,
      });
    } else {
      setError(fallbackMessage);
      throw e;
    }
  }

  const getUser = async (): Promise<User | null> => {
    if (!getUserPromise) {
      getUserPromise = (async () => {
        try {
          const {
            data: { result },
          } = await axios.get<{ result: User }>("user");
          setUser(result);
          return result;
        } catch (e) {
          if (e instanceof AxiosError && e.response?.status === 401) {
            // Ensure multiple user request failures
            // don't cause multiple redirects by
            // aborting the shared controller
            setLocation("/?logout=1&redirect_url=" + window.location.href);
            return null;
          } else {
            handleError(e as Error);
            return null;
          }
        } finally {
          getUserPromise = null;
        }
      })();
    }

    return getUserPromise;
  };

  const createMultiFactorEnrolment =
    async (): Promise<MultiFactorEnrolment | null> => {
      try {
        const {
          data: { result },
        } = await axios.post("user/multi-factor-enrolments", {
          method_type: "totp",
        });
        await getUser();
        return result;
      } catch (e) {
        handleError(e as Error);
        return null;
      }
    };

  const deleteMultiFactorEnrolment = async (
    id: string
  ): Promise<MultiFactorEnrolment | null> => {
    try {
      const enrolment = user?.multi_factor_enrolments?.find((e) => e.id === id);

      if (enrolment?.is_enabled) {
        const {
          data: { result },
        } = await requestMultiFactorCode({
          onSubmit: ({ code }) =>
            axios.delete<{ result: MultiFactorEnrolment }>(
              "user/multi-factor-enrolments/" + id + "?code=" + code
            ),
          onSubmitLabel: "Delete multi-factor method",
        });
        getUser();
        return result;
      } else {
        const {
          data: { result },
        } = await axios.delete<{ result: MultiFactorEnrolment }>(
          "user/multi-factor-enrolments/" + id
        );
        getUser();
        return result;
      }
    } catch (e) {
      handleError(e as Error);
      return null;
    }
  };

  const disableMultiFactorEnrolment = async (data: {
    id: string;
  }): Promise<MultiFactorEnrolment | null> => {
    try {
      const {
        data: { result },
      } = await requestMultiFactorCode({
        onSubmit: ({ code }) =>
          axios.patch<{ result: MultiFactorEnrolment }>(
            "user/multi-factor-enrolments/" + data.id + "/disable?code=" + code
          ),
        onSubmitLabel: "Disable multi-factor method",
      });
      getUser();
      return result;
    } catch (e) {
      handleError(e as Error);
      return null;
    }
  };

  const enableMultiFactorEnrolment = async (data: {
    id: string;
    password_confirmation: string;
  }): Promise<MultiFactorEnrolment | null> => {
    try {
      const {
        data: { result },
      } = await axios.patch(
        "user/multi-factor-enrolments/" + data.id + "/enable",
        data
      );
      getUser();
      return result;
    } catch (e) {
      handleError(e as Error);
      return null;
    }
  };

  const updateUser = async (data: Partial<User>): Promise<User | null> => {
    try {
      setIsLoading(true);
      setError(undefined);
      const {
        data: { result },
      } = await axios.patch("user", data);
      setUser(result);
      setIsLoading(false);
      if (embedded) {
        embeddedUpdatedModal.show();
      }
      return result;
    } catch (e) {
      handleError(e as Error);
      return null;
    }
  };

  const changePassword = async (data: {
    password_current: string;
    password: string;
    password_confirmation: string;
  }): Promise<User | null> => {
    try {
      setIsLoading(true);
      const {
        data: { result },
      } = await axios.patch("user/change-password", data);
      setUser(result);
      setIsLoading(false);
      if (embedded) {
        embeddedUpdatedModal.show();
      }
      return result;
    } catch (e) {
      handleError(e as Error);
      return null;
    }
  };

  const multiFactorEnrolment = useMemo(
    () => user?.multi_factor_enrolments[0],
    [user]
  );

  const resetState = () => {
    setIsLoading(false);
    setError(undefined);
    setUser(undefined);
  };

  const state = {
    error,
    isLoading,
    user,
  };

  return useMemo(() => {
    return {
      ...state,
      multiFactorEnrolment,
      setIsLoading,
      setError,
      resetState,
      createMultiFactorEnrolment,
      deleteMultiFactorEnrolment,
      enableMultiFactorEnrolment,
      disableMultiFactorEnrolment,
      getUser,
      updateUser,
      changePassword,
    };
  }, Object.values(state));
}

export function ProfileProvider({ children }: { children: ReactNode }) {
  const value = useProfileInternal();

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
}

export function useProfile() {
  const context = useContext(ProfileContext);
  if (context === undefined) throw Error();
  return context;
}
